import P1 from '@/images/gallery/1.jpg';
import P2 from '@/images/gallery/2.jpg';
import P3 from '@/images/gallery/3.jpg';
import P4 from '@/images/gallery/4.jpg';
import P5 from '@/images/gallery/5.jpg';
import P6 from '@/images/gallery/6.jpg';
import P7 from '@/images/gallery/7.jpg';
import P8 from '@/images/gallery/8.jpg';
import P9 from '@/images/gallery/9.jpg';
import P10 from '@/images/gallery/10.jpg';
import P11 from '@/images/gallery/11.jpg';
import P12 from '@/images/gallery/12.jpg';
import P13 from '@/images/gallery/13.jpg';
import P14 from '@/images/gallery/14.jpg';
import P15 from '@/images/gallery/15.jpg';
import P16 from '@/images/gallery/16.jpg';
import P17 from '@/images/gallery/17.jpg';
import P18 from '@/images/gallery/18.jpg';
import P19 from '@/images/gallery/19.jpg';
import P20 from '@/images/gallery/20.jpg';
import P21 from '@/images/gallery/21.jpg';
import P22 from '@/images/gallery/22.jpg';
import P23 from '@/images/gallery/23.jpg';
import P24 from '@/images/gallery/24.jpg';
// import P25 from '@/images/gallery/25.jpg';
// import P26 from '@/images/gallery/26.jpg';
// import P27 from '@/images/gallery/27.jpg';
// import P28 from '@/images/gallery/28.jpg';
// import P29 from '@/images/gallery/29.jpg';
// import P30 from '@/images/gallery/30.jpg';
// import P31 from '@/images/gallery/31.jpg';
// import P32 from '@/images/gallery/32.jpg';

export const photos = [
  // {
  //   // for first photo, please upload to blogger.com
  //   // this will help us to improve page performance
  //   original: `https://lh3.googleusercontent.com/-6kf0CzGMDss/YZ-SdXsBpeI/AAAAAAAAACA/43O2QDPXMgIBXByZshakS0JiX1G3DlcUQCLcBGAsYHQ/s16000/1.jpg`,
  // },
  {
    original: P1,
    // thumbnail: P1,
  },
  {
    original: P2,
    // thumbnail: P2,
  },
  {
    original: P3,
    // thumbnail: P3,
  },
  {
    original: P4,
    // thumbnail: P4,
  },
  {
    original: P5,
    // thumbnail: P5,
  },
  {
    original: P6,
    // thumbnail: P6,
  },
  {
    original: P7,
    // thumbnail: P7,
  },
  {
    original: P8,
    // thumbnail: P8,
  },
  {
    original: P9,
    // thumbnail: P9,
  },
  {
    original: P10,
    // thumbnail: P10,
  },
  {
    original: P11,
    // thumbnail: P11,
  },
  {
    original: P12,
    // thumbnail: P12,
  },
  {
    original: P13,
    // thumbnail: P13,
  },
  {
    original: P14,
    // thumbnail: P14,
  },
  {
    original: P15,
    // thumbnail: P15,
  },
  {
    original: P16,
    // thumbnail: P16,
  },
  {
    original: P17,
    // thumbnail: P17,
  },
  {
    original: P18,
    // thumbnail: P18,
  },
  {
    original: P19,
    // thumbnail: P19,
  },
  {
    original: P20,
    // thumbnail: P20,
  },
  {
    original: P21,
    // thumbnail: P21,
  },
  {
    original: P22,
    // thumbnail: P22,
  },
  {
    original: P23,
    // thumbnail: P23,
  },
  {
    original: P24,
    // thumbnail: P24,
  },
  // {
  //   original: P25,
  //   // thumbnail: P25,
  // },
  // {
  //   original: P26,
  //   // thumbnail: P26,
  // },
  // {
  //   original: P27,
  //   // thumbnail: P27,
  // },
  // {
  //   original: P28,
  //   // thumbnail: P28,
  // },
  // {
  //   original: P29,
  //   // thumbnail: P29,
  // },
  // {
  //   original: P30,
  //   // thumbnail: P30,
  // },
  // {
  //   original: P31,
  //   // thumbnail: P31,
  // },
  // {
  //   original: P32,
  //   // thumbnail: P32,
  // },
];
