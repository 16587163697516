import React from 'react';
import { bool } from 'prop-types';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './assets/styles.css';

import { photos } from './api/photo-data';

/**
 * function to render Photo Slider
 * This component using `react-image-gallery`
 * @returns {JSX.Element}
 */
function Gallery() {
  return (
    <ImageGallery
      autoPlay
      infinite
      lazyLoad
      items={photos}
      showBullets={false}
      showFullscreenButton={false}
      showThumbnails={false}
    />
  );
}

Gallery.propTypes = {
  withTitle: bool,
};

Gallery.defaultProps = {
  withTitle: false,
};

export default React.memo(Gallery);
